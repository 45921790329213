import Card from '../../components/Card'
import data from './data'
import './services.css'

const Services = () => {
  return (
    <section id="services">
      <h2>My Skills</h2>
      <p>I have IT skills in many areas of Information Technology</p>
      <div className="container services__container" data-aos="fade-up">
        {
          data.map(item => (
            <Card key={item.id} className="service light">
              <div className="service__icon">{item.icon}</div>
              <div className="service__details">
                <h4>{item.title}</h4>
                  <p>{item.desc}</p><br/>
                  <p>
                    <b><i>{item.list1}</i></b>
                  </p>
                  <br/>
                  <p>
                    <b><i>{item.list2}</i></b>
                  </p> 
                  <br/>
                  <p>
                    <b>{item.list3}</b>
                  </p>
                  <br/>
                  <p>
                    <b>{item.list4}</b>
                  </p>
                  <br/>
                  <p>
                    <b>{item.list5}</b>
                  </p>
                  <br/>
                  <p>
                    <b>{item.list6}</b>
                  </p>
              </div>
            </Card>
          ))
        }
      </div>
    </section>
  )
}

export default Services