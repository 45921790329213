import {SiAdobexd} from 'react-icons/si'
import {RiReactjsLine} from 'react-icons/ri'
import {FaServer} from 'react-icons/fa'
import {AiFillAppstore} from 'react-icons/ai'


const data = [
    {
    
        id: 1, icon: <SiAdobexd/>, title: 'Infrastrucure and Technical Specialist', desc: "My introducgtion to IT was as a technician and my technical skills have grown and developed. This area remains my core strength", list1: '-Networking and troubleshooting including firewalls, DNS, switching, routing and TCP/IP' , list2: '-Microsoft Server administration including Exchange Online, Active Directory, File and Print Servers, Terminal servers, Hyper-V virtualization',list3: '-Linux expert level in administration and applications using CentOS, Redhat, Debian, Fedora, Arch and Suse', list4: '-Monitoring and Incident Management using Nagios, ELK, Zabbix, Grafana, Prometheus, Jira and PagerDuty', list5: '-Automation scripting using PowerShell, Python and Bash',list6: '-Teaching and Mentoring',
    },
    {
        id: 2, icon: <RiReactjsLine/>, title: 'Full Stack Development', desc: 'I have worked with several frontend and backend stacks including:', list1: '- C#/ASP.NET/RESTFul API/React', list2:'- Python/Django/Flask', list3: '- MEAN/MERN stacks with frontend tools like Javascript, HTML5, CSS3, Angular18,TypeScript, React,and VueJS', list4: '- Backend datatbases and servers like MongoDB, NodeJS and SQL Server', list5: '-Agile methodologies designed to improve the SDLC', list6: '-Application development using C#, Winforms and Blazor, Python and PyQTGui, C++, Java SDK',
    },
    {
        id: 3, icon: <FaServer/>, title: 'DevOps', desc: "This bridges the gap perfectly between my skills in software development and IT operations.", list1: '-Continuous Integration (CI)/Continuous Delivery (CD) and Source and Version Control using Gitlab, SVN and Git', list2: "-Automation in testing and deployment using tools like Jenkins and Bamboo", list3: '-Configuration Management using tools like Puppet, Chef and Ansible', list4: '-Containerization and Orchestration using Docker and Kubernetes', list5: '-Infrastructure as Code (IaC) using tools like TerraForm and Ansible', list6: '-Cloud Terchnology using Azure and AWS to setup and manage virtual machines like EC2,Storage using S3, Costing and budgeting cloud resources, Backup and DR, monitoring using Azure Monitor', 
    },
    {
        id: 4, icon: <AiFillAppstore/>, title: 'SecOps', desc: 'I am passionate about Cyber Security and the role it plays currently and will play in the future of this Industry', list1: '-SIEM using Splunk and AlienVault', list2: "-IDS/IPS and auto response systems", list3: "-Honeypots using D-Pot, Cowrie and Dionaea", list4: '-Data Loss Prevention (DLP)',list5:'-Vulnerability assessments and penetration testing', list6: '-Security training and campaigns to highlight and emphasize the importance of security including phisphing tests and reporting',
    },
    {
        id: 5, icon: <AiFillAppstore/>, title: 'Automation', desc: 'My ability to innovate is concommitent with the overwhelming need to automate in business today', list1: 'Routine task automation like Software Updates, Backups and monitoring', list2: "-Automation Testing using nUnit, JUnit and Selenium ", list3: '-Workflow automation', list4: '-Robotic Process Automation(RPA)', list5: '-Atlassian stack automation using Bitbucket, Bamboo and Confluence',
    },
    {
        id: 6, icon: <AiFillAppstore/>, title: 'Developing Skills', desc: 'An imperative in the current technological climate is to continuously learn and improve and therefore I embark on several learning initiatives', list1: '-Artificial Intelligence including machine learning, deep learning and Natural language processing', list2: "-Data science particularly using Python’s NumPy and Pandas", List3: "-Business Analysis", list4: '-Management', 
    }
]


export default data