import {FaAward} from 'react-icons/fa'
import {TbBooks} from 'react-icons/tb'
import {BiHappyHeartEyes} from 'react-icons/bi'


const data = [
    {id: 1, icon: <FaAward/>, title: 'Experience', desc: '15+ Years Working'},
    {id: 2, icon: <TbBooks/>, title: 'Education', desc: 'BSc Computer Science'},
    {id: 3, icon: <BiHappyHeartEyes/>, title: 'Skills', desc: '70+ skills'}
    ]



    export default data;