import {HiOutlineMail} from 'react-icons/hi'
import {RiLinkedinBoxFill} from 'react-icons/ri'
import {FaWhatsapp} from 'react-icons/fa'
import {AiFillGithub} from 'react-icons/ai'

export const links = [
    {id: 1, link: '#', title: 'Home'},
    {id: 2, link: '#about', title: 'About'},
    {id: 3, link: '#services', title: 'Skills'},
    {id: 4, link: '#portfolio', title: 'Portfolio'},
    {id: 5, link: '#contact', title: 'Contact'}
]


export const socials = [
    {id: 1, icon: <HiOutlineMail/>, link: 'mailto:neilgovender2010@gmail.com'},
    {id: 2, icon: <RiLinkedinBoxFill/>, link: 'http://www.linkedin.com/in/neil-govender-b0885550'},
    {id: 3, icon: <FaWhatsapp/>, link: 'https://wa.me/+27829462396'},
    {id: 4, link: 'https://github.com/neilgov', icon: <AiFillGithub/>}
    
]