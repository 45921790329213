import Avatar1 from '../../assets/avatar1.jpg'
import Avatar2 from '../../assets/avatar2.jpeg'
import Avatar3 from '../../assets/avatar3.jpg'
import Avatar4 from '../../assets/avatar4.jpg'
import Avatar5 from '../../assets/avatar5.jpg'
import Avatar6 from '../../assets/avatar6.jpg'
import Avatar7 from '../../assets/avatar7.jpg'


const data = [
        {
        id: 1,
        quote: "Neil was very easy to deal with when he was working on our systems.",
        avatar: Avatar1,
        name: 'Josephine Govender ',
        profession: 'Administtrator - HHL Law '
        },
        {
        id: 2,
        quote: "Neil Govender is highly competent.He singlehandedly setup our network and IT systems when we relocated our law firm. He also manages and maintains our website",
        avatar: Avatar2,
        name: 'Amethyst Moodley',
        profession: 'Partner - HHL Law'
        },
        {
        id: 3,
        quote: "Glad to give him a reference. Contact me on 0317194099",
        avatar: Avatar3,
        name: 'Mark van Elden',
        profession: 'Infrastructure Manager - Smiths Manufacturing'
        },
        {
        id: 4,
        quote: "Neil helps with all my technical issues",
        avatar: Avatar4,
        name: 'Hemarani Govender',
        profession: 'Accountant'
        },
        {
        id: 5,
        quote: "Neil talks about IT so passionately I can't help learning",
        avatar: Avatar5,
        name: 'Joshua Nehemiah',
        profession: 'Friend'
        },
        {
        id: 6,
        quote: "Neil assisted me with my university project",
        avatar: Avatar6,
        name: 'Dereshan Moodley',
        profession: 'Student'
        },
        {
        id: 7,
        quote: "Neil is always helpful",
        avatar: Avatar7,
        name: 'Adrian Matthew',
        profession: 'Makeup Artist'
        }
    ]



    export default data