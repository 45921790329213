import {HiOutlineMail} from 'react-icons/hi'
import {RiLinkedinBoxFill} from 'react-icons/ri'
import {FaWhatsapp} from 'react-icons/fa'

const data = [
    {id: 1, icon: <HiOutlineMail/>, link: 'mailto:neilgovender2010@gmail.com'},
    {id: 2, icon: <RiLinkedinBoxFill/>, link: 'http://www.linkedin.com/in/neil-govender-b0885550'},
    {id: 3, icon: <FaWhatsapp/>, link: 'https://wa.me/+27829462396'}
]


// alternative whatsApp link
// https://wa.me/0829462396
// https://api.whatsapp.com/send/?phone=%27829462396


export default data