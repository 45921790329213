import Image1 from '../../assets/project1.jpg'
import Image2 from '../../assets/project2.jpg'
import Image3 from '../../assets/project3.jpg'
import Image4 from '../../assets/project4.jpg'
import Image5 from '../../assets/project5.jpg'
import demo1 from '../../assets/demo1.mp4'
import Image6 from '../../assets/project6.jpg'
import Image7 from '../../assets/project7.jpg'
import Image8 from '../../assets/project8.jpg'
import Image9 from '../../assets/project9.jpg'

const data = [
    {
        id: 1,
        category: 'Full Stack Development',
        image: Image1,
        title: "Portfolio",
        desc: "This is the Portfolio site you are currently viewing.I have uploaded the source code to GitHub",
        demo: demo1,
        github: 'https://github.com/neilgov/profile'
    },
    {
        id: 2,
        category: 'Full Stack Development',
        image: Image2,
        title: "Employee App",
        desc: "This is a C#/ASP.Net application I wrote 2 years ago to track employees. I will have to relaunch this application so you can see the demo",
        demo: '',
        github: 'https://github.com/neilgov/Employee'
    },
    {
        id: 3,
        category: 'Automation',
        image: Image3,
        title: "Selenium Web Scraping",
        desc: "This application when launched will scrape a website/API for particular information",
        demo: '',
        github: 'https://github.com/neilgov/'
        
    },
    {
        id: 4,
        category: 'Full Stack Development',
        image: Image4,
        title: "MERN currency Converter",
        desc: "Thiss application uses a nodeJS backend to make a request to exchange rate API ",
        demo: '',
        github: 'https://github.com/neilgov/Employee'
        
    },
    {
        id: 5,
        category: 'Machine Learning',
        image: Image5,
        title: "Building a chatbot using NLP",
        desc: "This machine learning application will use machine learning and NLP to build a simple chatbot. ",
        demo: 'http://egatortutorials.com',
        github: 'https://github.com/neilgov'
        
    }
   
   
 
]


export default data