const data = [
    {
      id: 1,
      question: "Are you on the job market?",
      answer: "Yes, indeed. I am available almost immediately"
    },
    {
      id: 2,
      question: "What is your current CTC?",
      answer: "Please contact me privately for a negotiation regarding that aspect on +27829462396"
    },
    {
      id: 5,
      question: "Are you willing to relocate?",
      answer: "I currently reside in sunny Durban and would like to remain here. I am willing to travel though and I can work remotely as I have an home office. And times of work doesn't bother me either because I don't sleep"
    },
    {
      id: 4,
      question: "What are your greatest strengths and weaknesses?",
      answer: "I am innovative, hard working and I try not to give up. My greatest weakness is strong willed, opinionated and willing to die on that hill sometimes for some causes that may not necessarily warrant it."
    },
    {
      id: 3,
      question: "What type of role are you looking for considering you have many different skills in IT?",
      answer: "I think the future of IT is bright and I believe I can function in both a strategical and technical role to make this field better"
    },
    {
      id: 6,
      question: "Why should we hire you?",
      answer: "The easy answer would be that I am experienced or skilled.But you should hire me because I am a good communicator and I would be able to train and mentor others."
    }
  ]

  export default data